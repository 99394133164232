<script setup lang="ts">
import { FormNewsletterSchema } from '@/schemas'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import {
  createValidationSchema,
  KInlineFormInput,
  KText,
  useForm,
} from '@sozialinfo/kompass'
import { actions } from 'astro:actions'
import { computed, ref } from 'vue'

const submitted = ref(false)
const isSubmitting = ref(false)
const success = ref(false)
const message = ref('')

const validationSchema = createValidationSchema(FormNewsletterSchema)

const { handleSubmit } = useForm({
  validationSchema,
})

const subscribeToNewsletter = handleSubmit(async (values) => {
  isSubmitting.value = true
  const formData = new FormData()
  formData.append('email', values.email)
  const result = await actions.newsletter(formData)
  const { data, error } = result as { data: any, error: any }

  isSubmitting.value = false
  if (error) {
    success.value = false
    message.value = error.message
    submitted.value = true
    return
  }

  success.value = data.success
  message.value = data.message
  submitted.value = true
})

const messageTextColorClass = computed(() => {
  return success.value ? 'bg-primary text-primary-foreground' : 'bg-destructive text-destructive-foreground'
})
</script>

<template>
  <div
    v-auto-animate
    class="flex flex-col gap-2"
  >
    <KText
      class="font-bold text-primary"
      tag="p"
      paragraph="label"
    >
      Newsletter abonnieren
    </KText>
    <KText
      tag="p"
      paragraph="label"
      class="pb-2"
    >
      Ich möchte alle zwei Wochen über aktuelle Themen, Projekte und Neuigkeiten informiert werden.
    </KText>
    <div
      v-if="submitted"
      class="
        flex items-center justify-center rounded-xs px-4 py-1.5 text-fluid-xxxs
      "
      :class="[messageTextColorClass]"
      role="region"
      aria-live="polite"
    >
      <KText
        tag="span"
        paragraph="label"
        role="alert"
      >
        {{ message }}
      </KText>
    </div>
    <form
      method="POST"
      action="/_actions/newsletter"
      @submit.prevent="subscribeToNewsletter"
    >
      <KInlineFormInput
        name="email"
        label="E-Mail"
        button-label="Senden"
      />
    </form>
  </div>
</template>
